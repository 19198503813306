import { DynamicFormatter } from '@gowgates/dynamic-fields';
import { MyTask, TaskColumnConfig } from '../../types';
import TaskStatus from '../claim/show/Tasks/TaskStatus';

type Props = {
  myTask: MyTask;
  columnConfig: TaskColumnConfig;
};

export const MyTaskColumn = ({ myTask, columnConfig }: Props) => {
  if (columnConfig.dataType === 'dynamic' && columnConfig.model !== 'task') {
    return (
      <DynamicFormatter data={myTask[columnConfig.model]?.data || {}} field={columnConfig.field} />
    );
  }

  if (columnConfig.dataType === 'fixed') {
    if (columnConfig.model === 'task') {
      if (columnConfig.field.name === 'status') {
        return <TaskStatus task={myTask} />;
      }

      return <DynamicFormatter data={myTask} field={columnConfig.field} />;
    } else {
      return <DynamicFormatter data={myTask[columnConfig.model]} field={columnConfig.field} />;
    }
  }

  return null;
};
