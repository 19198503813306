import { Table, TableProps } from 'antd';

import { useTable } from '@gowgates/core';
import { Link } from 'react-router-dom';

import { claimRowClassName } from '../../utils/tables';
import useAppConfigs from '../../hooks/useAppConfigs';
import { Pagination, MyTask, TaskColumnConfig } from '../../types';
import TaskCol from '../tasks/TaskCol';
import ClaimRowIcons from '../claims/ClaimRowIcons';
import { MyTaskColumn } from './MyTaskColumn';

type Props = {
  tasks?: MyTask[] | undefined;
  loading: boolean;
  pagination: Pagination;
};

export const TasksTable = ({ tasks = [], loading, pagination }: Props) => {
  const { handleTableChange } = useTable<MyTask>();
  const { appConfigs } = useAppConfigs();

  const columnConfigs = appConfigs.homepage.tasksTableColumns as TaskColumnConfig[];

  const columns: TableProps<MyTask>['columns'] = columnConfigs.map((columnConfig) => ({
    title: columnConfig.field.label,
    dataIndex: `${columnConfig.model}.${columnConfig.field.name}`,
    render: (_text: string, record: MyTask) => (
      <TaskCol task={record}>
        <MyTaskColumn myTask={record} columnConfig={columnConfig} />
      </TaskCol>
    )
  }));

  columns?.push({
    className: 'text-right claim-info-icons',
    render: (_text, record) => (
      <Link to={`/claims/${record.id}`} className="col-clickable">
        <ClaimRowIcons record={record.claim} />
      </Link>
    )
  });

  return (
    <Table
      dataSource={tasks}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      className="page-table"
      rowClassName={(record) => claimRowClassName(record.claim)}
      columns={columns}
    />
  );
};
