import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getMyTasks, getStatistics } from '../../api/endpoints';
// import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import Statistics from './Statistics';
import { TasksTable } from './Table';

export const Home = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  // const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  // const [sorter, setSorter] = useState({});
  usePageTitle('Dashboard');

  const { isFetching: isFetchingStats, data: statistics } = useQuery({
    queryKey: ['statistics'],
    queryFn: getStatistics
  });

  const { isFetching: isFetchingTasks, data: myTasks } = useQuery({
    queryKey: ['myTasks', search],
    queryFn: () => getMyTasks(search)
  });

  useEffect(() => {
    if (myTasks) setPagination((prevPagination) => ({ ...prevPagination, ...myTasks.pagination }));
  }, [myTasks]);

  // useEffect(() => {
  //   setSorter(updatedSorterFromParams(search));
  //   setFilters(updatedFiltersFromParams(search));
  // }, [search]);

  return (
    <Page title={t('statistics.mine')} overlap>
      <Statistics statistics={statistics} loading={isFetchingStats} />

      <h3>{t('statistics.openTasks')}</h3>

      <div className="page-table">
        <TasksTable tasks={myTasks?.rows} loading={isFetchingTasks} pagination={pagination} />
      </div>
    </Page>
  );
};
